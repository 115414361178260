<template>
  <div class="form-payment">
    <b-container>
      <b-row class="justify-content-center align-items-center">
        <b-col cols="12">
          <b-row class="mt-5 pt-5">
            <b-col
              v-for="(item, index) in payments"
              :key="index"
              class="px-0"
              cols="6"
            >
              <b-card
                class="p-2 m-1"
                no-body
                :style="`${payment_method == item.value ? 'background-color: aliceblue;' : ''}border-radius: 12px;`"
              >
                <b-form-checkbox
                  v-model="payment_method"
                  :value="item.value"
                  class="custom-control-success custom__checkbox"
                >
                  <div
                    role="button"
                    class="d-flex align-items-center"
                  >
                    <div class="d-flex justify-content-center align-items-center rounded-circle image-box box-tempo">
                      <b-img
                        :src="require(`@/assets/images/icons/${item.image}.svg`)"
                        blank-color="#ccc"
                        width="18"
                        alt="placeholder"
                      />
                    </div>
                    <div class="ml-1">
                      <h6 class="text-dark fw-bold-800">
                        {{ item.name }} <span class="text-danger">*</span>
                      </h6>
                      <h6 class="text-dark text-darken-4 fw-bold-500 size12">
                        Pembayaran Langsung
                      </h6>
                    </div>
                  </div>
                </b-form-checkbox>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
    <b-modal
      id="modal-form-payment"
      hide-footer
      centered
      size="lg"
      title="Detail Pembayaran"
      ok-only
      ok-title="Accept"
    >
      <template #modal-header>
        <h5 class="modal-title">Detail Pembayaran</h5>
        <b-button class="close size20 py-25 d-flex align-items-center" role="button" @click="resetModal">
          x
        </b-button>
      </template>
      <Giro v-if="payment_method == 'giro'" @formPayment="getFormPayment" />
      <Kredit v-if="payment_method == 'card'" @formPayment="getFormPayment" />
      <TransferBank v-if="payment_method == 'transfer'" @formPayment="getFormPayment" />
    </b-modal>
    <InputAmount
      :title="total"
      :subtitle="'Uang diterima'"
    />
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BImg, BFormCheckbox, VBToggle, BContainer,
} from 'bootstrap-vue'
import InputAmount from '@/components/Cashier/Modal/InputAmount.vue'
import Giro from '@/components/Payment/Giro/FormStep1.vue'
import Kredit from '@/components/Payment/Kredit/FormStep1.vue'
import TransferBank from '@/components/Payment/TransferBank/index.vue'

export default {
  components: {
    BCard,
    BImg,
    BFormCheckbox,
    BRow,
    BCol,
    BContainer,
    InputAmount,
    Giro,
    Kredit,
    TransferBank,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      total: this.$store.state.stockWarehouse.totalProduct,
      is_cash: false,
      payment_method: '',
      payments: [
        {
          name: 'Tunai',
          value: 'cash',
          image: 'tunai',
          class: 'image-box box-tempo',
        },
        {
          name: 'Transfer Bank',
          value: 'transfer',
          image: 'transfer',
          class: 'image-box box-transfer',
        },
        {
          name: 'Kredit atau Debit',
          value: 'card',
          image: 'kredit',
          class: 'image-box box-kredit',
        },
        {
          name: 'Giro',
          value: 'giro',
          image: 'giro',
          class: 'image-box box-giro',
        },
      ],
    }
  },
  watch: {
    '$store.state.stockWarehouse.totalProduct': function (value) {
      this.total = value
    },
    payment_method(value) {
      const banks = ['giro', 'card', 'transfer']
      this.$store.commit('stockWarehouse/setPaymentMethod', value)
      if (value === 'cash') {
        this.$bvModal.show('modal-input-discount')
      }

      if (banks.includes(value)) {
        this.$bvModal.show('modal-form-payment')
      }
    },
  },
  methods: {
    resetModal() {
      this.$bvModal.hide('modal-form-payment')
      this.$store.commit('stockWarehouse/SET_PAYMENT_DETAIL', {})
      localStorage.setItem('payloadPayment', JSON.stringify({}))
    },
    getFormPayment(payload) {
      this.$store.commit('stockWarehouse/SET_PAYMENT_DETAIL', payload)
      localStorage.setItem('payloadPayment', JSON.stringify(payload))
    },
  },
}
</script>

<style lang="scss">

.form-payment {
  .custom-checkbox {
    .custom-control-label::after,
    .custom-control-label::before {
      right: 4%;
      top: 35%;
      left: inherit;
    }
    .custom-control-input:focus ~ .custom-control-label::before {
      box-shadow: none !important;
    }
    .custom-control-label::before {
      border: none;
    }
  }
  .custom-control-label {
    width: 100%;
  }
  .custom-checkbox.custom-control {
    padding-left: 0;
  }
  .image-box {
    width: 46px;
    height: 46px;
  }
  .card {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
    border-radius: 12px;
  }
}
</style>

<style scoped>
.modal-body {
  background: #E4E5EC;
}
</style>
