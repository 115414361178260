<template>
  <b-modal
    id="modal-input-discount"
    centered
    hide-header
    hide-footer
    title="Vertically Centered"
    ok-only
    ok-title="Accept"
    @hide="$bvModal.hide('modal-input-discount')"
  >
    <div class="p-2 d-flex justify-content-between">
      <feather-icon
        class="text-dark font-weight-bolder cursor-pointer"
        size="20"
        icon="ArrowLeftIcon"
        @click="$bvModal.hide('modal-input-discount')"
      />
      <h1 class="h4 text-dark font-weight-bolder mb-0" v-if="parseFloat(title)">
        <span class="h4 text-dark font-weight-bolder mb-0" v-if="amountDp > 0">{{ amountDp | formatAmount }} DP</span>
        <span class="h4 text-dark font-weight-bolder mb-0" v-else>{{ parseFloat(title) | formatAmount }} Tunai</span>
      </h1>
      <h1 class="h4 text-dark font-weight-bolder mb-0" v-else>
        <span class="h4 text-dark font-weight-bolder mb-0" v-if="amountDp > 0">{{ amountDp | formatAmount }} DP</span>
        <span class="h4 text-dark font-weight-bolder mb-0" v-else>{{ parseFloat(title) | formatAmount }} Tunai</span>
      </h1>
      <div />
    </div>
    <hr class="my-0">
    <div class="discount-field">
      <div>
        <h1 class="h6 text-dark text-darken-4 mb-0">
          {{ subtitle }}
        </h1>
        <h1 class="mb-0 discount-number">
          {{ parseFloat(amount) || 0 | formatAmount }}
        </h1>
      </div>
    </div>
    <div class="grid-container">
      <b-button
        id="button--discount__PzQl"
        v-for="(option, index) in optionsAmount"
        :key="index"
        class="grid-item option-number"
        @click="amount += option"
      >
        {{ parseFloat(option) | formatAmount }}
      </b-button>
      <b-button
        id="button--handle__press"
        v-for="(button, index) in buttons"
        :key="`number-${index}`"
        class="grid-item list-number"
        :class="button.className"
        @click="handlePress"
      >
        {{ button.name }}
      </b-button>
      <b-button
        id="button--save__amount"
        class="grid-item list-number"
        :class="{'button-simpan disabled' : amount == 0, 'button-active' : amount > 0}"
        :disabled="amountDp && amountDp > 0 ? amount < amountDp || amount > amountDp : amount < parseInt(title)"
        @click="saveAmount"
      >
        Proses
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import {
  BModal, VBToggle, BButton,
} from 'bootstrap-vue'
import {
    preformatFloat, formatRupiah
  } from '@/auth/utils'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BModal,
    BButton,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  setup() {
    return {
      preformatFloat, formatRupiah
    }
  },
  /* eslint-disable vue/require-default-prop */
  props: {
    title: {
      type: '',
    },
    subtitle: {
      type: String,
    },
    isSubmitPaymentTunai: {
      type: Boolean,
    },
    submitPaymentTunai: {
      type: Function,
    },
    createUtang: {
      type: Function,
    },
  },
  data() {
    return {
      amountDp: parseFloat(preformatFloat(this.$route.query.amount_dp)),
      is_ritel: true,
      amount: 0,
      optionsAmount: [10000, 50000, 100000],
      buttons: [
        {
          id: 1,
          name: '1',
          className: '',
        },
        {
          id: 2,
          name: '2',
          className: '',
        },
        {
          id: 3,
          name: '3',
          className: '',
        },
        {
          id: 4,
          name: '4',
          className: '',
        },
        {
          id: 5,
          name: '5',
          className: '',
        },
        {
          id: 6,
          name: '6',
          className: '',
        },
        {
          id: 7,
          name: '7',
          className: '',
        },
        {
          id: 8,
          name: '8',
          className: '',
        },
        {
          id: 9,
          name: '9',
          className: '',
        },
        {
          id: 10,
          name: 'C',
          className: '',
        },
        {
          id: 11,
          name: '0',
          className: '',
        },
      ],
    }
  },
  watch: {
    title(value) {
      this.optionsAmount.splice(0, 1, this.pushAmountToOptions(value))
    },
  },
  mounted() {
    this.optionsAmount.splice(0, 1, this.pushAmountToOptions(this.title))
  },
  methods: {
    pushAmountToOptions(value) {
      const amountDp = parseFloat(preformatFloat(this.$route.query.amount_dp))
      if (amountDp){
        return this.$route.query.amount_dp && amountDp > 0 ? amountDp : parseFloat(value) || this.$route.query.total;
      } else {
        return value;
      }
    },
    handlePress(event) {
      const number = event.target.textContent.trim()
      switch (number) {
        case 'C': this.clearDisplay()
          break
        case '0':
        case '1':
        case '2':
        case '3':
        case '4':
        case '5':
        case '6':
        case '7':
        case '8':
        case '9': this.numberPressed(number)
          break
        default:
          // eslint-disable-next-line no-console
          console.log('KEY ERROR: in default')
      }
    },
    numberPressed(number) {
      if (this.amount === 0) {
        this.amount = number
      } else if (this.amount.length >= 15) {
        // eslint-disable-next-line no-console
        console.log('KEY ERROR: Display limit reached')
      } else {
        this.amount += number
      }
    },
    saveAmount() {
      if (this.amount > 0) {
        this.$store.commit('cashier/setDiscount', this.amount)
        localStorage.setItem('price', this.amount)
        localStorage.setItem('cash_total_payment', this.amount)
        this.$bvModal.hide('modal-input-discount')
        this.amount = 0
        if (!this.isSubmitPaymentTunai && this.$route.name !== 'stock.add.index' && this.$route.name !== 'debt.index') {
          this.$router.push({ name: 'payment.tunai' })
        }
        if (this.isSubmitPaymentTunai) {
          this.submitPaymentTunai()
        }
        if (this.$route.name == 'debt.index') {
          this.createUtang()
        }
      }
    },
    clearDisplay() {
      this.amount = 0
    },
  },
}
</script>

<style lang="scss">
@import '/src/assets/scss/variables/_variables.scss';

#modal-input-discount .grid-container .grid-item.option-number:last-child {
  border-radius: 0px 16px 0px 0px;
}
#modal-input-discount {
  .discount-field {
    height: 180px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;

    .discount-number {
      font-weight: 700;
      font-size: 42px;
      line-height: 150%;
      color: #333348;
    }
  }

  .grid-container {
    display: grid;
    grid-template-columns: auto auto auto;

    .grid-item {
      text-align: center;
      border-radius: 0;
      border: 0;
      width: 151px;

      &.option-number {
        background-color: #EFF1F5 !important;
        color: #000 !important;
        font-weight: 700;
        font-size: 14px;

        &:nth-child(3) {
          border-radius: 0px 16px 0px 0px;
        }

        &:first-child {
          border-radius: 16px 0px 0px 0px;
        }
      }

      &.list-number {
        color: #7A7F94 !important;
        background-color: #fff !important;
        padding: 20px;
        font-weight: 700;
        font-size: 28px;

        &:not(:last-child) {
          border-right: 1px solid #E4E5EC !important;
          border-bottom: 1px solid #E4E5EC !important;
        }
      }

      &.button-simpan {
        color: #fff !important;
        background-color: #CCCFD3 !important;
        font-size: 16px !important;
      }

      &.button-active {
        color: #fff !important;
        background-color: $primary !important;
        font-size: 16px !important;
      }

      &:hover {
        box-shadow: none;
      }
    }
  }

  .modal-dialog {
    position: absolute;
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    bottom: 0;
    width: 453px;

    .modal-content {
      background: #FCFCFC;
      border-radius: 16px 16px 0px 0px;

      .modal-body {
        padding: 0;
        .form-group {
          label {
            font-size: 14px;
            color: $dark;
            font-weight: 600;
          }
          input {
            background: #E7E7ED;
            border: none;
            border-radius: 8px;
            height: 52px;
            color: $dark;
            font-weight: 600;
          }
        }
        .button__group {
          display: flex;
          width: 100%;

          .button__cancel {
            background-color: #FFFFFF !important;
            border-color: #fff !important;
            color: $dark !important;
            box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.04);
            border-radius: 0px 0px 0px 16px;
            width: 100%;
            height: 72px;
            font-size: 16px;
            font-weight: 800;
          }

          .button__save {
            background-color: #45B6AB !important;
            border-color: #45B6AB !important;
            box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.04);
            border-radius: 0px 0px 16px 0px;
            width: 100%;
            height: 72px;
            font-size: 16px;
            font-weight: 800;
          }

        }
      }
    }
  }
}
</style>
