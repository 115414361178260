<template>
  <div class="form-payment">
    <b-card
      class="p-2 mx-3 mt-3"
      no-body
      style="border-radius: 12px;"
      :class="payment.payment_type == 'tunai' ? 'active-tunai' : ''"
    >
      <b-form-checkbox
        v-model="payment.payment_type"
        value="tunai"
        class="custom-control-success custom__checkbox"
        role="button"
      >
        <div
          class="d-flex align-items-center"
        >
          <div class="d-flex justify-content-center align-items-center rounded-circle image-box box-tempo">
            <b-img
              :src="require('@/assets/images/icons/tunai.svg')"
              blank-color="#ccc"
              width="18"
              alt="placeholder"
            />
          </div>
          <div class="ml-1">
            <h6 class="text-dark fw-bold-800">
              Tunai <span class="text-danger">*</span>
            </h6>
            <h6 class="text-dark text-darken-4 fw-bold-500 size12">
              Pembayaran Langsung
            </h6>
          </div>
        </div>
      </b-form-checkbox>
    </b-card>
    <b-card
      class="p-2 mx-3 mt-3"
      no-body
      style="border-radius: 12px;"
      :class="payment.payment_type == 'tempo' ? 'active-tempo' : ''"
    >
      <b-form-checkbox
        v-model="payment.payment_type"
        value="tempo"
        class="custom-control-success custom__checkbox"
        role="button"
      >
        <div
          class="d-flex align-items-center"
        >
          <div class="d-flex justify-content-center align-items-center rounded-circle image-box box-tunai">
            <b-img
              :src="require('@/assets/images/icons/tempo.svg')"
              blank-color="#ccc"
              width="18"
              alt="placeholder"
            />
          </div>
          <div class="ml-1">
            <h6 class="text-dark fw-bold-800">
              Tempo <span class="text-danger">*</span>
            </h6>
            <h6 class="text-dark text-darken-4 fw-bold-500 size12">
              Pembayaran langsung dengan uang tunai
            </h6>
          </div>
        </div>
      </b-form-checkbox>
      <Transition
        name="fade"
        mode="out-in"
      >
        <b-collapse
          v-if="payment.payment_type == 'tempo'"
          id="collapse-2-inner"
          visible
          class=""
        >
          <b-row>
            <b-col cols="2" />
            <b-col cols="10">
              <hr>
              <div>
                <b-form-datepicker
                  id="example-datepicker"
                  v-model="payment.tempo_due_date"
                  class="mb-1"
                  locale="id"
                  placeholder="Pilih tanggal"
                />
              </div>
            </b-col>
          </b-row>
        </b-collapse>
      </Transition>
    </b-card>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BImg, BFormCheckbox, BCollapse, VBToggle, BFormRadio, BFormDatepicker,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BImg,
    BFormCheckbox,
    BCollapse,
    BFormRadio,
    BRow,
    BCol,
    BFormDatepicker,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      is_cash: false,
      selected: 'C',
      payment: {
        payment_type: '',
        tempo_due_date: '',
        tunai_type: '',
        payment_method: '',
      },
    }
  },
  watch: {
    payment: {
      handler(value) {
        this.$store.commit('supplier/SET_PAYMENT_TYPE', value.payment_type)
        try {
          this.$store.commit('stockWarehouse/setPayment', value)
        } catch (err) {
          console.log(err)
        }
      },
      deep: true,
    },
  },
  mounted() {
    const top = localStorage.getItem('terms_of_payment_day') || 0
    const today = new Date(this.$store.state.stockWarehouse.formSupplier.date)
    today.setDate(today.getDate() + parseInt(top, 10))
    this.payment.tempo_due_date = today.toISOString().substring(0, 10)
  },
}
</script>

<style lang="scss">

@mixin tunai {
  background: #D7FDFB;
  box-shadow: inset 1.67273px 1.67273px 3.34545px rgba(230, 219, 211, 0.2);
}

@mixin tempo {
  background: #FEF9F5;
  box-shadow: inset 1.67273px 1.67273px 3.34545px rgba(230, 219, 211, 0.2);
}

// .card {
//   background-color: #FFFFFF;
// }

.form-payment {
  .custom-checkbox {
    .custom-control-label::after,
    .custom-control-label::before {
      display: none;
      right: 4%;
      top: 35%;
      left: inherit;
    }
    .custom-control-input:focus ~ .custom-control-label::before {
      box-shadow: none !important;
    }
    .custom-control-label::before {
      border: none;
    }
  }
  .custom-control-label {
    width: 100%;
  }
  .custom-checkbox.custom-control {
    padding-left: 0;
  }
  .image-box {
    width: 46px;
    height: 46px;
  }
}
</style>

<style lang="scss" scoped>
.form-payment {
  .card.active-tunai {
    background-color: #FEF9F5 !important;
    transition: all 0.5s;
    .box-tunai {
      box-shadow: unset !important;
    }
  }
  .card.active-tempo {
    background-color: #D7FDFB !important;
    transition: all 0.5s;
    .box-tempo {
      box-shadow: unset !important;
    }
  }
}
</style>
