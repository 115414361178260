<template>
  <b-modal
    id="modal-add-product-in"
    hide-header
    hide-footer
    ok-only
    ok-title="Accept"
    centered
    size="lg"
    title="Large Modal"
    class="custom__modal"
  >
    <header
      class="sticky-top border border-light-bottom border-light-line border-light-top border-light-line p-2"
    >
      <div class="d-flex align-items-center justify-content-between">
        <div
          v-if="step == 1"
          style="cursor: pointer"
          @click="
            $bvModal.hide('modal-add-product-in'),
              $store.commit('stockWarehouse/setTotalProduct', total)
          "
        >
          <feather-icon
            size="24"
            class="text-dark font-weight-bolder"
            icon="XIcon"
          />
          <span class="text-dark text-darken-4 fw-bold-600 size14 ml-1"
            >Batal</span
          >
        </div>
        <div v-else @click.stop="stepForm('prev')">
          <feather-icon
            size="24"
            class="text-dark font-weight-bolder"
            icon="ChevronLeftIcon"
          />
          <span class="text-dark text-darken-4 fw-bold-600 size14 ml-1"
            >Kembali</span
          >
        </div>
        <h1 class="text-dark h6 font-weight-bolder mb-0">
          Tambahkan Produk Masuk {{ textHeader || "" }}
        </h1>
        <b-button
          v-if="step == 1"
          class="px-3"
          :disabled="disabledStep1"
          @click="stepForm('next')"
        >
          <!-- :disabled="Object.values(formSupplier).some(value => value === '')" -->
          Lanjut
        </b-button>
        <b-button
          v-else-if="step == 2"
          class="px-3"
          :disabled="disabled"
          @click="stepForm('next')"
        >
          Lanjut
        </b-button>
        <b-button
          v-else-if="step == 3"
          class="px-3"
          @click="
            payment.payment_type == 'tempo'
              ? $store.state.stockWarehouse.isWarehouse
                ? submitWarehouse()
                : submitSupplier()
              : stepForm('next')
          "
        >
          Simpan
        </b-button>
        <b-button
          v-else-if="step == 4"
          class="px-3"
          :disabled="isLoading"
          @click.prevent="
            $store.state.stockWarehouse.isWarehouse
              ? submitWarehouse()
              : submitSupplier()
          "
        >
          <b-spinner v-if="isLoading" />
          Simpan
        </b-button>
      </div>
    </header>

    <div v-if="step == 3" class="text-center mt-5 pt-5">
      <h6 class="text-dark text-darken-4 fw-bold-800 mb-1">Total Harga</h6>
      <h5 class="size24 text-dark fw-bold-800 mb-1">
        {{ total | formatAmount }}
      </h5>
      <!-- <h6 class="text-dark text-darken-4 size12 fw-bold-800">
        Sudah di potong diskon Rp 230.000
      </h6> -->
    </div>

    <b-row
      class="mx-0 mt-5 pt-5 justify-content-center"
      :class="step >= 3 ? 'd-none' : step == 2 ? 'sticky-top' : ''"
      :style="step == 2 ? 'z-index: 100;margin-top: 82px !important;' : ''"
    >
      <b-col lg="5">
        <b-row>
          <b-col cols="12 mb-4">
            <div class="mb-2">
              <span class="text-dark text-darken-4 fw-bold-600 size14"
                >Langkah {{ step }} dari 3 – Lengkapi Info</span
              >
            </div>
            <b-progress
              :value="progressValue"
              max="100"
              class="progress-bar-success"
              variant="success"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row
      class="sticky-top px-3"
      style="margin-top: 200px !important; z-index: 200"
      v-if="comp == 'FormPurchaseOrder'"
    >
      <b-col cols="2">
        <h6 class="mb-0">Nama produk <span class="text-danger">*</span></h6>
      </b-col>
      <b-col class="mr-1 pr-0" style="max-width: 4rem">
        <h6 class="mb-0">Qty <span class="text-danger">*</span></h6>
      </b-col>
      <b-col class="custom__pricelist--row">
        <h6 class="mb-0">Pricelist Rp <span class="text-danger">*</span></h6>
      </b-col>
      <b-col class="pl-0 custom__ppn--row">
        <h6 class="mb-0">Sudah PPN</h6>
      </b-col>
      <b-col class="">
        <h6 class="mb-0">Diskon</h6>
      </b-col>
      <b-col class="pl-0">
        <h6 class="mb-0">Total Diskon</h6>
      </b-col>
      <b-col>
        <h6 class="mb-0">Total Rp</h6>
      </b-col>
      <b-col cols="12">
        <hr class="mb-0" />
      </b-col>
    </b-row>
    <b-row
      class="mx-0 mt-2 justify-content-center"
      :style="step == 2 ? 'margin-top: 260px !important;' : ''"
    >
      <b-col :lg="step == 2 ? '12' : step == 4 ? '8' : '5'">
        <keep-alive>
          <component :is="comp" />
        </keep-alive>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import {
  BButton,
  BButtonGroup,
  BDropdown,
  BDropdownItem,
  BImg,
  BPagination,
  BFormSelect,
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BProgress,
  BSpinner,
} from "bootstrap-vue";

import vSelect from "vue-select";
import {
  checkPermission,
  successNotification,
  errorNotification,
} from "@/auth/utils";
import FormAddIncomingProduct from "@/components/Stock/FormWizard/FormAddIncomingProduct.vue";
import FormPurchaseOrder from "@/components/Stock/FormWizard/FormPurchaseOrder.vue";
import FormPayment from "@/components/Stock/FormWizard/FormPayment.vue";
import FormListPayment from "@/components/Stock/FormWizard/FormListPayment.vue";

export default {
  components: {
    BButton,
    BButtonGroup,
    BDropdown,
    BDropdownItem,
    BImg,
    BPagination,
    BFormSelect,
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BProgress,
    BForm,
    vSelect,
    FormAddIncomingProduct,
    FormPurchaseOrder,
    FormPayment,
    FormListPayment,
    BSpinner,
  },
  props: {
    getStockSupplier: {
      type: Function,
    },
    getStockWarehouse: {
      type: Function,
    },
    textHeader: {
      type: String,
    },
  },
  data() {
    return {
      comp: "FormAddIncomingProduct",
      step: 1,
      progressValue: 35,
      formPayload: {},
      formSupplier: this.$store.state.stockWarehouse.formSupplier,
      formProducts: this.$store.state.stockWarehouse.formProducts,
      payment: {},
      isLoading: false,
      total: this.$store.state.stockWarehouse.totalProduct,
      disabled: true,
      disabledStep1: true,
    };
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    };
  },
  watch: {
    step(value) {
      this.switchStep(value);
      // eslint-disable-next-line eqeqeq
      if (value == 3 || value == 4) {
        document.getElementById(
          "modal-add-product-in___BV_modal_body_"
        ).style.background = "#f6f7fa";
      } else {
        document.getElementById(
          "modal-add-product-in___BV_modal_body_"
        ).style.background = "#ffffff";
      }
    },
    "$store.state.stockWarehouse.totalProduct": function (value) {
      this.total = value;
    },
    "$store.state.stockWarehouse.formSupplier": {
      handler(value) {
        if (this.$store.state.stockWarehouse.isWarehouse) {
          this.formSupplier = {
            date: value.date,
            supplier_uuid: value.supplier_uuid,
            warehouse_uuid: value.warehouse_uuid,
            delivery_numbers: value.delivery_numbers,
          };
          this.disabledStep1 = Object.values(this.formSupplier).some(
            (el) => el === "" || el === undefined
          );
        }
        if (!this.$store.state.stockWarehouse.isWarehouse) {
          this.formSupplier = {
            date: value.date,
            supplier_uuid: value.supplier_uuid,
            shipping: value.shipping || "",
            po_so_id: value.po_so_id,
            delivery_numbers: value.delivery_numbers,
          };
          this.disabledStep1 =
            Object.values(this.formSupplier).some((el) => el === "") ||
            this.formSupplier.delivery_numbers.some(
              (el) => el.delivery_number_note === ""
            );
        }
      },
      deep: true,
    },
    "$store.state.stockWarehouse.formProducts": {
      handler(value) {
        this.formProducts = value;
        this.disabledButtonFormPuchaseOrder();
      },
      deep: true,
    },
    "$store.state.stockWarehouse.payment": {
      handler(value) {
        this.payment = value;
      },
      deep: true,
    },
  },
  destroyed() {
    localStorage.setItem("cash_total_payment", 0);
    this.step = 1;
  },
  beforeDestroy() {
    this.step = 1;
  },
  mounted() {
    if (this.$route.name == "stock.gudang") {
      delete this.formSupplier.shipping;
      delete this.formSupplier.items;
    }
  },
  methods: {
    switchStep(steps) {
      switch (steps) {
        case 1:
          this.comp = "FormAddIncomingProduct";
          this.progressValue = 35;
          break;
        case 2:
          this.comp = "FormPurchaseOrder";
          this.progressValue = 70;
          break;
        case 3:
          this.comp = "FormPayment";
          this.progressValue = 100;
          // this.submit()
          break;
        default:
          this.comp = "FormListPayment";
          this.progressValue = 100;
          break;
      }
    },
    disabledButtonFormPuchaseOrder() {
      if (
        this.formProducts.items.every((e) => e.qty > 0 || e.qty !== "") &&
        this.formProducts.items.every((e) => e.product_uuid.label) &&
        this.formProducts.items.every((e) => e.price > 0 || e.price !== "")
      ) {
        this.disabled = false;
      } else {
        this.disabled = true;
      }
    },
    async submitWarehouse() {
      this.isLoading = true;
      this.formPayload = {
        date: this.formSupplier.date || "",
        delivery_numbers: this.formSupplier.delivery_numbers,
        supplier_uuid: this.formSupplier.supplier_uuid,
        po_so_id: this.formSupplier.po_so_id,
        warehouse_uuid: this.formSupplier.warehouse_uuid,
        items: this.formProducts.items,
        additional_fee: this.formProducts.additional_fee,
        additional_fee_note: this.formProducts.additional_fee_note,
        note: this.formProducts.note,
        payment_type: this.payment.payment_type,
        tempo_due_date: this.payment.tempo_due_date,
        tunai_type: this.payment.tunai_type,
        payment_method: this.$store.state.stockWarehouse.payment_method,
      };

      if (this.formPayload.payment_type === "tunai") {
        delete this.formPayload.tempo_due_date;
      }
      if (this.formPayload.payment_type === "tempo") {
        delete this.formPayload.tunai_type;
      }

      const form = this.preparePayload();

      await this.$store
        .dispatch("stockWarehouse/postData", {
          uuid: "",
          payload: form,
        })
        .then(() => {
          this.step = 1;
          this.getStockWarehouse();
          this.isLoading = false;
          successNotification(
            this,
            "Success",
            "Stok gudang berhasil ditambahkan"
          );
          this.$bvModal.hide("modal-add-product-in");
        })
        .catch((err) => {
          this.isLoading = false;
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
    async submitSupplier() {
      this.isLoading = true;
      this.formPayload = {
        date: this.formSupplier.date || "",
        shipping: this.formSupplier.shipping,
        supplier_uuid: this.formSupplier.supplier_uuid,
        delivery_numbers: this.formSupplier.delivery_numbers,
        po_so_id: this.formSupplier.po_so_id,
        warehouse_uuid: this.formSupplier.warehouse_uuid,
        items: this.formProducts.items,
        additional_fee: this.formProducts.additional_fee,
        additional_fee_note: this.formProducts.additional_fee_note,
        note: this.formProducts.note,
        payment_type: this.payment.payment_type,
        tempo_due_date: this.payment.tempo_due_date,
        tunai_type: this.payment.tunai_type,
        payment_method: this.$store.state.stockWarehouse.payment_method,
      };

      if (this.formPayload.payment_type === "tunai") {
        delete this.formPayload.tempo_due_date;
      }
      if (this.formPayload.payment_type === "tempo") {
        delete this.formPayload.tunai_type;
        delete this.formPayload.payment_method;
      }

      const form = this.preparePayload();

      await this.$store
        .dispatch("stockWarehouse/postStockSupplier", {
          uuid: "",
          payload: form,
        })
        .then(() => {
          this.step = 1;
          this.isLoading = false;
          this.getStockSupplier();
          successNotification(
            this,
            "Success",
            "Stok supplier berhasil ditambahkan"
          );
          this.$bvModal.hide("modal-add-product-in");
        })
        .catch((err) => {
          this.isLoading = false;
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
    preparePayload() {
      const form = new FormData();
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.formPayload) {
        // eslint-disable-next-line no-prototype-builtins
        if (
          this.formPayload.hasOwnProperty(key) &&
          this.formPayload[key] != null
        ) {
          if (key !== "items" && key !== "delivery_numbers") {
            form.append(key, this.formPayload[key]);
          }
        }
      }
      // eslint-disable-next-line no-plusplus
      if (this.formPayload.items) {
        for (let i = 0; i < this.formPayload.items.length; i++) {
          // eslint-disable-next-line no-restricted-syntax
          for (const key of Object.keys(this.formPayload.items[i])) {
            if (
              key !== "disc_percents" &&
              key !== "price" &&
              key !== "qty" &&
              key !== "product_uuid"
            ) {
              form.append(
                `items[${i}][${key}]`,
                this.formPayload.items[i][key]
              );
            }
            if (key === "price" || key === "qty") {
              // eslint-disable-next-line radix
              form.append(
                `items[${i}][${key}]`,
                parseFloat(this.preformatFloat(this.formPayload.items[i][key]))
              );
            }
            if (key === "product_uuid") {
              // eslint-disable-next-line radix
              form.append(
                `items[${i}][${key}]`,
                this.formPayload.items[i][key].label ||
                  this.formPayload.items[i][key]
              );
            }
            // form.append(`items[${i}].disc_percents[${key}]`, this.formPayload.items[i].disc_percents[key])
          }
          for (
            let index = 0;
            index < this.formPayload.items[i].discounts.length;
            index++
          ) {
            const element = this.formPayload.items[i].discounts[index];
            // eslint-disable-next-line no-restricted-syntax
            for (const key in element) {
              if (Object.hasOwnProperty.call(element, key)) {
                const items = element[key];
                form.append(`items[${i}][discounts][${index}][${key}]`, items);
              }
            }
          }
        }
      }

      if (this.formPayload.delivery_numbers) {
        for (
          let index = 0;
          index < this.formPayload.delivery_numbers.length;
          index++
        ) {
          const element = this.formPayload.delivery_numbers[index];
          form.append(
            `delivery_numbers[${index}][delivery_number_note]`,
            element.delivery_number_note
          );
        }
      }
      return form;
    },
    preformatFloat(float) {
      if (!float) {
        return "";
      }

      // Index of first comma
      const posC = float.indexOf(",");

      if (posC === -1) {
        // No commas found, treat as float
        return float.replace(/\./g, "");
      }

      // Index of first full stop
      const posFS = float.indexOf(".");

      if (posFS === -1) {
        // Uses commas and not full stops - swap them (e.g. 1,23 --> 1.23)
        return float.replace(/\,/g, ".");
      }

      // Uses both commas and full stops - ensure correct order and remove 1000s separators
      return posC < posFS
        ? float.replace(/\,/g, "")
        : float.replace(/\./g, "").replace(",", ".");
    },
    stepForm(type) {
      if (type === "next") {
        this.step += 1;
      } else {
        this.step -= 1;
      }
      this.switchStep(this.steps);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@/assets/scss/variables/_variables.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";

@media screen and (min-height: 480px) and (max-height: 1024px) and (orientation: landscape) {
  .custom__pricelist--row {
    max-width: 12rem !important;
  }
  .custom__ppn--row {
    max-width: 5rem !important;
  }
  .custom__pricelist--col {
    max-width: 9.5rem !important;
    margin-right: 2rem !important;
    .custom__input {
      max-width: 9.5rem !important;
    }
  }
  .custom__ppn--col {
    max-width: 5rem !important;
  }
}

#modal-add-product-in {
  .modal-dialog {
    max-width: 100%;
    margin: 0;

    .modal-content {
      height: 100vh;

      .modal-body {
        padding: 0;
        overflow-y: scroll;

        .progress-bar-success {
          background-color: #eff1f5;
          .progress-bar {
            background: linear-gradient(
              269.89deg,
              #6cdfd4 0%,
              #45b6ab 98.81%
            ) !important;
            box-shadow: 0px 6px 8px rgba(44, 129, 120, 0.06);
            border-radius: 20px;
          }
        }

        label {
          font-size: 14px;
          color: $dark;
          font-weight: 600;
        }

        .vs__open-indicator {
          fill: rgba(60, 60, 60, 0.5);
        }

        .custom__input {
          height: 52px;
          background: #eff1f5;
          border: 1px solid #e4e5ec;
          border-radius: 8px;
        }

        .vs__dropdown-toggle {
          height: 52px;
          background-color: #eff1f5;
          border: 1px solid #e4e5ec;
          border-radius: 8px;
        }
      }
    }
  }
}
</style>
