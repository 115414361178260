<template>
  <b-container class="mt-3" :class="$router.name == 'payment.split' ? 'px-0' : ''">
    <b-row class="justify-content-center">
      <b-col :class="$router.name == 'payment.split' ? 'px-0' : ''" :cols="$route.name == 'stock.add.index' || $route.name == 'debt.index' ? 12 : column">
        <b-form @submit.prevent="submitForm">
          <b-row>
            <!-- Nama Bank Penerima -->
            <b-col
              cols="12"
              class="mb-2"
            >
              <b-form-group class="custom__form--input mb-0">
                <label for="">Rekening {{ this.$route.name === 'stock.add.index' ? 'Pengirim' : 'Penerima' }} <span class="text-danger">*</span></label>
                <b-form-select
                  v-model="formPayload.giro_bank_account_payment_uuid"
                  :options="optionsBankAccount"
                  required
                />
              </b-form-group>
            </b-col>

            <!-- Nominal -->
            <b-col
              v-if="$route.name == 'pos.index'"
              cols="12"
              class="mb-2"
            >
              <b-form-group class="custom__form--input mb-0">
                <label for="">Nominal <span class="text-danger">*</span></label>
                <input
                  :id="`amount-giro-${indexNominal}`"
                  class="focus-0 custom__input pl-1 w-100"
                  type="text"
                  :value="formPayload.cash_total_payment"
                  @input="getAmountGiro"
                  @change="getAmountGiro"
                  required
                >
              </b-form-group>
            </b-col>

            <!-- Pilih Bank Pengirim -->
            <b-col
              cols="12"
              class="mb-2"
            >
              <b-form-group class="custom__form--input mb-0">
                <label for="">Pilih Bank Pengirim</label>
                <b-form-select
                  v-model="formPayload.giro_bank"
                  :options="options"
                  required
                />
              </b-form-group>
            </b-col>

            <!-- Nama Pemilik Pengirim -->
            <b-col
              cols="12"
              class="mb-2"
            >
              <b-form-group class="custom__form--input mb-0">
                <label for="">Nama Pemilik Pengirim</label>
                <b-form-input
                  id="faktur"
                  v-model="formPayload.giro_account_name"
                  class="custom__input"
                  type="text"
                  placeholder="Nama Pemilik Pengirim"
                  required
                />
              </b-form-group>
            </b-col>

            <!-- Nomor Giro -->
            <b-col
              cols="12"
              class="mb-2"
            >
              <b-form-group class="custom__form--input mb-0">
                <label for="">Nomor Giro</label>
                <b-form-input
                  id=""
                  v-model="formPayload.giro_account_number"
                  class="custom__input"
                  type="number"
                  placeholder="x-xxxx-xxxx"
                  required
                />
              </b-form-group>
            </b-col>

            <!-- Tanggal Penerbitan -->
            <b-col
              cols="12"
              class="mb-2"
            >
              <b-form-group class="custom__form--input mb-0">
                <label for="date-creation">Tanggal Penerbitan</label>
                <b-form-input
                  id="date-creation"
                  v-model="formPayload.giro_creation_date"
                  class="custom__input pt-1"
                  type="date"
                  placeholder="x-xxxx-xxxx"
                  required
                />
              </b-form-group>
            </b-col>

            <!-- Tanggal Pencairan -->
            <b-col
              cols="12"
              class="mb-2"
            >
              <b-form-group class="custom__form--input mb-0">
                <label for="date-effective">Tanggal Pencairan</label>
                <b-form-input
                  id="date-effective"
                  v-model="formPayload.giro_effective_date"
                  class="custom__input pt-1"
                  type="date"
                  placeholder="x-xxxx-xxxx"
                  required
                />
              </b-form-group>
            </b-col>

            <!-- Button -->
            <b-col
              v-if="!isButtonHide"
              cols="12"
              class="mb-2 mt-2"
            >
              <b-button
                type="submit"
                class="btn-block size16 py-1 border-8"
              >
                Selanjutnya
              </b-button>
            </b-col>

          </b-row>
        </b-form>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {
  BContainer, BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BFormSelect,
} from 'bootstrap-vue'
import { formatRupiah } from '@/auth/utils'

import vSelect from 'vue-select'

export default {
  props: {
    isSubmit: {
      type: Boolean
    },
    submit: {
      type: Function,
    },
    isButtonHide: {
      type: Boolean,
      default: false,
    },
    createUtang: {
      type: Function,
    },
    column: {
      type: Number,
      default: 5,
    },
    formData: {
      type: Object,
    },
    indexNominal: {
      type: Number,
    }
  },
  components: {
    BContainer,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    vSelect,
    BFormSelect,
  },
  setup() {
    return {
      formatRupiah
    }
  },
  data() {
    return {
      selected: null,
      options: [],
      formPayload: {
        giro_account_name: '',
        giro_account_number: '',
        giro_bank: '',
        giro_bank_account_payment_uuid: '',
        giro_creation_date: '',
        giro_effective_date: '',
        cash_total_payment: ''
      },
      optionsBankAccount: [],
    }
  },
  watch: {
    formData: {
      handler(value) {
        this.formPayload = value
        setTimeout(() => {
          this.getAmountGiro()
        }, 100);
      },
      deep: true,
    },
    formPayload: {
      handler(value) {
        const payload = {
          ...value,
        }
        this.$emit('payloadGiro', payload)
        this.$emit('formPayment', this.formPayload)
      },
      deep: true,
    },
  },
  mounted() {
    this.getBank()
    this.getBankAccounts()

    const transaction_query = JSON.parse(
      localStorage.getItem("transaction_query")
    );

    if (transaction_query.data) {
      const decodeData = transaction_query.data
      setTimeout(() => {
        for (let index = 0; index < decodeData.length; index++) {
          const element = decodeData[index];
          if (this.indexNominal === index && element.payment_method == 'giro') {
            this.formPayload = {
              ...element
            }
          }
        }
      }, 500);
    }

    // Re assign cash total payment on same index
    this.formPayload.cash_total_payment = this.formData.cash_total_payment
    
    // if (transaction_query.total) {
    //   setTimeout(() => {
    //     this.formPayload.cash_total_payment = transaction_query.total || '';
    //     setTimeout(() => {
    //       this.getAmountGiro();
    //     }, 200);
    //   }, 300);
    // }
  },
  destroyed() {
    this.formPayload = {
      giro_account_name: '',
      giro_account_number: '',
      giro_bank: '',
      giro_creation_date: '',
      giro_effective_date: '',
    }
  },
  methods: {
    getAmountGiro() {
      const rupiah = document.getElementById(`amount-giro-${this.indexNominal}`)
      rupiah.value = this.formatRupiah(rupiah.value, '')
      this.formPayload.cash_total_payment = rupiah.value
    },
    submitForm() {
      if (this.$route.name == 'stock.add.index') {
        this.$emit('formPayment', this.formPayload)
        this.$bvModal.hide('modal-form-payment')
        return
      }
      if (this.$route.name == 'debt.index') {
        this.createUtang()
        return
      }
      if (this.isSubmit) {
        this.submit()
        return
      } else {
        this.$store.commit('formWizard/setStep', 2)
        return
      }
    },
    async getBank() {
      await this.$store.dispatch('bank/getData', {
        uuid: '',
        params: {
          per_page: 30,
        },
      }).then(result => {
        const items = result.data.data.data
        // eslint-disable-next-line array-callback-return
        items.map(el => {
          this.options.push({
            value: el.name,
            text: el.name,
          })
        })
        this.options.unshift({ value: '', text: 'Pilih Bank' })
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(err)
      })
    },
    async getBankAccounts() {
      await this.$store.dispatch('bankAccounts/getData', {
        params: {
          per_page: 100,
        },
      }).then(result => {
        const items = result.data.data.data;
        const filtered = items.filter(el => el.account_type === 'Giro');
        // eslint-disable-next-line array-callback-return
        items.map(el => {
          this.optionsBankAccount.push({
            value: el.uuid,
            text: `${el.owner_name} - ${el.bank?.name} - ${el.account_number}`,
          });
        });
        this.optionsBankAccount.unshift({ value: '', text: 'Pilih Rekening' });
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
</style>
